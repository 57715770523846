module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DALY 2: the next treatment option for DLBCL may start her","short_name":"DALY 2","description":"DALY 2 clinical trials for the investigation of the new investigational medicinal product, zamtocabtagene autoleucel, a personalized CAR T- cell therapy for patients suffering from diffuse large B cell lymphoma (DLBCL).","start_url":"/","lang":"en","background_color":"#3c1192","theme_color":"#3c1192","display":"standalone","icon":"src/images/logos/daly2_favicon.png","crossOrigin":"use-credentials","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"3b78984f4983c641bbd20caf142af862"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
